<template>
  <div class="contain">
    <div class="modalityTop">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs">
        <el-breadcrumb-item>巡线系统管理</el-breadcrumb-item>
        <el-breadcrumb-item><span>事件管理</span></el-breadcrumb-item>
      </el-breadcrumb>
      <el-form
        ref="search"
        class="searchForm"
        :model="paramsData"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus">
          <el-date-picker
            @change="search"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="巡线员" prop="equipmentStatus" >
          <el-select
            clearable
            style="width:170px"
            v-model="paramsData.userId"
            placeholder="请选择巡线员"
          >
            <el-option
              v-for="item in peopleList"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件类型" prop="equipmentStatus" >
          <el-select
            clearable
            style="width:170px"
            v-model="paramsData.eventStatus"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in userStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id">
          </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件状态" prop="equipmentStatus" >
          <el-select
            clearable
            style="width:170px"
            v-model="paramsData.eventStatus"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in userStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id">
          </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检点状态" prop="equipmentStatus" >
          <el-select
            clearable
            style="width:170px"
            v-model="paramsData.checkpoint"
            placeholder="请选择状态"
          >
            <el-option
          v-for="item in sourceStatus"
          :key="item.id"
          :label="item.label"
          :value="item.id">
      </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus">
          <el-input
          clearable
            placeholder="请输入任务编号/任务名称/执行人员姓名"
            v-model="paramsData.searchText"
            style="width:340px"
          >
          </el-input>
        </el-form-item>
       
        <!-- <el-button
          size="mini"
          type="primary"
          @click="inquire"
          style="width:80px;height:40px"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="reset"
          style="margin-left: 10px;width:80px;height:40px"
          @click="reset"
          >重置</el-button
        > -->
         <div style="margin:10px 0;">
        <el-button type="primary" icon="el-icon-search" @click="search" size="mini" style="">搜索</el-button>
        <el-button @click="resize" type="primary" size="mini" class="EquipmentMarginLeft"
                   icon="el-icon-refresh">重置
        </el-button>
        <!-- <el-button type="success" size="mini" icon="el-icon-plus" @click="addGroup">添加</el-button> -->
        <el-button type="danger"  size="mini" class="EquipmentMarginLeft"
                   @click="MutiDeleteFun" icon="el-icon-delete">批量删除
        </el-button>
        <el-button size="mini" icon="el-icon-plus" @click="deriveExcel">导出</el-button>
      </div>
      </el-form>
    </div>
    <!-- <template v-if="this.paramsData.ids==''||this.paramsData.ids==null">
    搜索条件：
    <el-input size="mini" type="text" v-model="paramsData.searchText" placeholder="类型/创建人/地址" style="width: 200px"></el-input>
    事件状态：
    <el-select clearable  size="mini" style="width:130px;" class="EquipmentMarginLeft" v-model="paramsData.eventStatus" placeholder="事件状态" @change="search">
      <el-option
          v-for="item in userStatus"
          :key="item.id"
          :label="item.label"
          :value="item.id">
      </el-option>
    </el-select>
    巡检点状态：
    <el-select clearable  size="mini" style="width:130px;" class="EquipmentMarginLeft" v-model="paramsData.checkpoint" placeholder="巡检点状态" @change="search">
      <el-option
          v-for="item in sourceStatus"
          :key="item.id"
          :label="item.label"
          :value="item.id">
      </el-option>
    </el-select>
    时间范围：
    <el-date-picker
        @change="search"
        size="mini"
        v-model="DataPicker"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        value-format="yyyy-MM-dd"
        end-placeholder="结束日期">
    </el-date-picker>
    巡线员:
    
    <el-select size="mini" clearable  class="EquipmentMarginLeft" value-key="userId" v-model="paramsData.eventPeopleIds" placeholder="巡线员" @change="search">
          <el-option
              v-for="item in peopleList"
              :key="item.id"
              :label="item.username"
              :value="item.id">
          </el-option>
    </el-select>
      <div style="margin:10px 0;">
        <el-button type="primary" icon="el-icon-search" @click="search" size="mini" style="">搜索</el-button>
        <el-button @click="resize" type="primary" size="mini" class="EquipmentMarginLeft"
                   icon="el-icon-refresh">重置
        </el-button>
        <el-button type="success" size="mini" icon="el-icon-plus" @click="addGroup">添加</el-button>
        <el-button type="danger"  size="mini" class="EquipmentMarginLeft"
                   @click="MutiDeleteFun" icon="el-icon-delete">批量删除
        </el-button>
        <el-button size="mini" icon="el-icon-plus" @click="deriveExcel">导出</el-button>-->
     

  <!-- </template> -->
  <div class="modalityCenter">
     <el-button
          size="mini"
          type="primary"
          class="addButton"
          @click="addGroup"
          >添加</el-button
        >
  <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      max-height="550"
      :cell-style="{textAlign:'center'}"
      :header-cell-style="{
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 900,
        background: '#DCDFE8',
      }"
    >
    <el-table-column align="center" prop="State" type="selection" width="80"></el-table-column>
      <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
      <el-table-column property="eventCodeName" label="事件类型" align="center"></el-table-column>
      <el-table-column property="eventResultsName" label="事件状态" align="center">
      </el-table-column>
      <el-table-column property="createTime" label="创建时间" align="center"></el-table-column>
      <el-table-column property="createBy" label="创建人" align="center"></el-table-column>
      <el-table-column property="eventAddress" label="地址" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column property="checkingPoint" label="巡检点" align="center">
        <template slot-scope="{row}">
          <el-tag v-if="row.checkingPoint==0" type="info">否</el-tag>
          <el-tag v-if="row.checkingPoint==1" type="success">是</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="State" label="操作">
        <template slot-scope="{row}">
          <el-button v-if="row.eventResults==1||row.eventResults==0" type="text" @click="handle(row)" size="small">关闭</el-button>
          <el-button v-if="row.eventResults==2" type="text" @click="delhandle(row)" size="small">删除</el-button>
          <el-button type="text" @click="seeDetil(row)" size="small">详情</el-button>
          <el-button type="text" @click="del(row)" size="small" style="color:red">{{ row.checkingPoint==0?'设置巡检点':'取消巡检点' }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paramsData.current"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="paramsData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="eventstotal"
      >
      </el-pagination>
    </div>
  </div>
    <!-- <el-table :data="tableData"
              style="margin-top: 10px"
              @selection-change="handleSelectionChange"
              ref="multipleTable"
              :row-style="{height:'30px'}"
              :cell-style="{padding:'10px'}"
              :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
      <el-table-column align="center" prop="State" type="selection" width="80"></el-table-column>
      <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
      <el-table-column property="eventCodeName" label="事件类型" align="center"></el-table-column>
      <el-table-column property="eventResultsName" label="事件状态" align="center"> -->
        <!-- <template slot-scope="{row}">
          <el-tag v-if="row.eventStatus==2" type="success">已完成</el-tag>
          <el-tag v-if="row.eventStatus==1" type="danger">处理中</el-tag>
        </template> -->
      <!-- </el-table-column>
      <el-table-column property="createTime" label="创建时间" align="center"></el-table-column>
      <el-table-column property="createBy" label="创建人" align="center"></el-table-column>
      <el-table-column property="eventAddress" label="地址" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column property="checkingPoint" label="巡检点" align="center">
        <template slot-scope="{row}">
          <el-tag v-if="row.checkingPoint==0" type="info">否</el-tag>
          <el-tag v-if="row.checkingPoint==1" type="success">是</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="State" label="操作">
        <template slot-scope="{row}">
          <el-button v-if="row.eventResults==1||row.eventResults==0" type="text" @click="handle(row)" size="small">关闭</el-button>
          <el-button v-if="row.eventResults==2" type="text" @click="delhandle(row)" size="small">删除</el-button>
          <el-button type="text" @click="seeDetil(row)" size="small">详情</el-button>
          <el-button type="text" @click="del(row)" size="small" style="color:red">{{ row.checkingPoint==0?'设置巡检点':'取消巡检点' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          style="margin-top:10px;"
          background
          layout="total,sizes,prev, pager, next,jumper"
          :total="eventstotal"
          :page-sizes="[10,30,50,100]"
          :page-size="paramsData.size"
          :current-page="paramsData.current"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div> -->
    <el-dialog
        class="img_Dialog"
        title="事件信息详情"
        :visible.sync="detilVisible">
      <table class="view-table">
        <tr>
          <th>事件类型</th>
          <td>
            <span>{{detiltable.eventCodeName}}</span>
          </td>
          <th>事件状态</th>
          <td>
            <span>{{detiltable.eventResultsName}}</span>
          </td>
        </tr>
        <tr>
          <th>创建人</th>
          <td>
            <span>{{detiltable.createBy}}</span>
          </td>
          <th>创建时间</th>
          <td>
            <span>{{ detiltable.createTime }}</span>
          </td>
        </tr>
        <tr>
          <th>巡线员</th>
          <td colspan="3">
            <span>{{detiltable.eventPeopleName}}</span>
          </td>
        </tr>
        <tr>
          <th>事件地址</th>
          <td colspan="3">{{detiltable.eventAddress}}</td>
        </tr>
        <tr>
          <th>事件备注</th>
          <td colspan="3">{{detiltable.eventSource == 0?detiltable.eventRemark:detiltable.eventDescribe}}</td>
        </tr>
        <tr>
          <th>附件</th>
          <td colspan="3">
            <viewer :images="detiltable.urls">
            <div v-for="(item,index) in detiltable.unConfirmedUrls" :key="index" style="float: left;margin-left: 10px">
                 <el-image 
                      style="width: 100px; height: 100px"
                      :src="item" 
                      :preview-src-list="detiltable.unConfirmedUrls">
                  </el-image>
            </div>
            </viewer>
          </td>
        </tr>
      </table>
      <div v-if="detiltable.confirmedUrls.length>0">
        <el-divider>巡线员提交</el-divider>
        <table class="view-table">
          <tr>
            <th>事件描述</th>
            <td colspan="3">{{detiltable.eventDescribe}}</td>
          </tr>
          <tr>
            <th>附件</th>
            <td colspan="3">
              <viewer :images="detiltable.urls">
                <div v-for="(item,index) in detiltable.confirmedUrls" :key="index" style="float: left;margin-left: 10px">
                    <el-image 
                      style="width: 100px; height: 100px"
                      :src="item" 
                      :preview-src-list="detiltable.confirmedUrls">
                    </el-image>
                </div>
              </viewer>
            </td>
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detilVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="添加事件"
        width="40%"
        :visible.sync="showAddModel"
        :show-close="false"
        :close-on-click-modal="false"
        @close="closeeventDialog('form')">
      <el-form ref="form" :model="form" label-width="100px" :rules="rule">
          <el-form-item label="事件类型：" prop="eventCodeName">
            <el-input v-model="form.eventCodeName" @focus="showEventType" placeholder="请选择事件类型"></el-input>
          </el-form-item>
          
          <el-form-item label="事件状态："  prop="eventResults">
            <el-radio  v-model="form.eventResults" label="0">待确认</el-radio>
            <el-radio  v-model="form.eventResults" label="1">已确认</el-radio>
          </el-form-item>
        <el-form-item label="地址：" prop="eventAddress">
          <el-input v-model="form.eventAddress" @focus="showModel = true" placeholder="请选择地址"></el-input>
        </el-form-item>
        <el-form-item label="巡线员：" prop="eventPeopleIds" v-if="!radioDisable">
          <el-select clearable style="width:100%;" class="EquipmentMarginLeft" value-key="userId" v-model="form.eventPeopleIds" placeholder="报送人">
            <el-option
                v-for="item in peopleList"
                :key="item.id"
                :label="item.username"
                :value="item.id">
            </el-option>
            <!-- <el-option
                v-for="item in peopleList"
                :key="item.userId"
                :label="item.userName"
                :value="item">
            </el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="附件:">
          <el-upload
              ref="upload"
              action="#"
              :auto-upload="true"
              :limit="8"
              :http-request="repairBeforUploadFun"
              :id="repairBeforFilefileList.length>9?'disUoloadSty':''"
              :on-change="beforHFhandleChangeImg"
              :before-remove="beforhandleRemove"
              :before-upload="beforUploadImg"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :file-list="repairBeforFilefileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="form.eventRemark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
          <el-button @click="showAddModel = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :visible.sync="showModel"
        :show-close="false"
        :close-on-click-modal="false">
      <mapDialog  @closeModal="closeModel"></mapDialog>
    </el-dialog>
    <el-dialog
        title="选择事件类型"
        :visible.sync="showeventDialog"
        :show-close="true"
        :close-on-click-modal="false"
        @closed="showIndex=null">
      <el-collapse accordion >
        <el-collapse-item v-for="item in eventTypeList" :title="item.name" :key="item.id" >
          <div :class="showIndex==person.id?'activeClass':'noactiveClass'" style="" v-for="(person,index) in item.children" :key="person.id" @click="clickNode(item.name,person)">{{index+1}}-{{person.name}}</div>
        </el-collapse-item>
      </el-collapse>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showeventDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  ByPostpeople,
  closeevent,
  deleteBatchevent,
  dicByType,
  eventPage,
  zypatrolGetUserList,
  patrolGetDict,
  eventPagedetil, saveevent,excelEvents,
  setcheckpoint, uploadImg
} from "../../RequestPort/maintenance";
import mapDialog from './mapDialog'
import { geoOws } from '@/apis/geo'
export default {
  name: "index",
  components:{mapDialog},
  watch:{
    'form.eventResults'(e){
      if(e == 1){
        this.radioDisable = true
        this.form.eventPeopleIds = ''
      }else{
        this.radioDisable = false
      }
    }
  },
  data(){
    return{
      radioDisable:false,
      repairBeforFileFormData: new FormData(),
      repairBeforFilefileList:[],
      showIndex:null,
      showModel:false,
      detilVisible:false,
      showAddModel:false,
      showeventDialog:false,
      paramsData:{
        current:1,
        size: 100,
        searchText:'',
        eventStatus:'',
        checkpoint:'',
        eventPeopleIds:'',
        ids:this.$route.query.eventIds,
      },
      form:{
        eventCodeName:'',
        eventAddress:'',
        eventResults:'0',
        detectionConcentrationUnit:'ppm',
        confirmConcentrationUnit:'ppm',
        locations: [],
        eventPeopleIds: '',
        eventPeoples:[],
        urls:[],

      },
      rule:{
        eventCodeName:[{ required: true, message: '请选择事件类型', trigger: 'change' }],
        eventAddress:[{ required: true, message: '请选择地址', trigger: 'change' }],
        eventPeopleIds:[{ required: true, message: '请选择报送人', trigger: 'change' }]
      },
      detiltable:{
        confirmedUrls:[]
      },
      sourceStatus:[{label:'是',id:'1'},{label:'否',id:'0'}],
      unit:['ppm','vol%'],
      userStatus:[{label:'待确认',id:'0'},{label:'已确认',id:'1'},{label:'已完成',id:'2'}],
      jianlouList:[{label:'已排除',id:'1'},{label:'已确认',id:'2'},{label:'待确认',id:'0'}],
      DataPicker:[],
      tableData:[],
      eventTypeList:[],
      eventPeopleIds:[],
      peopleList:[],
      eventstotal:0,
      multipleSelection:[]
    }
  },
  methods:{
    deriveExcel(){
      let ids = []
      this.multipleSelection.forEach((item,index)=>{
        ids.push(item.id)
      })
      excelEvents({checkpoint:this.paramsData.checkpoint,ids:ids.toString(),
        eventStatus:this.paramsData.eventStatus,
        searchText:this.paramsData.searchText,
        eventPeopleIds:this.paramsData.eventPeopleIds}).then((e)=>{
          window.open(e.data)

      })
    },
    EventStatusChange(e){
    },
   async repairBeforUploadFun(e){
      this.repairBeforFileFormData.append('file',e.file)
    await  uploadImg(this.repairBeforFileFormData).then((res)=> {
        this.form.urls.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
    },
    beforUploadImg(file){
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ['jpg','jpeg','png','gif','bmp','JPG','JPEG','PBG','GIF','BMP'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
    },
    beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList
    },
    beforhandleRemove(file) {
      let index = this.getArrayIndex(this.repairBeforFilefileList,file);
      this.form.urls.splice(index,1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    addGroup(){
      this.showAddModel = true

      // ByPostpeople({code:'managerPerson'}).then(res=>{
      //   this.peopleList = res.data
      // })
    },
    closeeventDialog(form){
      this.form={
        eventCodeName:'',
        eventAddress:'',
        eventResults:'0',
        detectionConcentrationUnit:'ppm',
        confirmConcentrationUnit:'ppm',
        eventPeopleIds:'',
        eventPeoples:[],
        urls:[]
      }
      this.$refs[form].resetFields()
      this.$refs.upload.clearFiles()
      this.eventPeopleIds = []
    },
    clickNode(item,person){
      this.showIndex = person.id
      this.form.eventCodeId = person.id
      this.form.eventCodeName = item + '->'+person.name
      this.$forceUpdate()
    },
    showEventType(){
      dicByType({parentCode:'GAS_PATROL_EVENT',level:2}).then(res=>{
        if(res.code===200){
          this.eventTypeList = res.data
          this.showeventDialog = !this.showeventDialog
        }
      })
      // patrolGetDict({dictType: 3}).then(res => {
      //     this.eventTypeList = res.data
      //     this.showeventDialog = !this.showeventDialog
      // })
    },
    submitAdd(form){
      this.$refs.form.validate(valid => {
        if (valid) {
          if(this.form.eventCodeId){
            if(this.form.eventAddress){
              this.getNetwork()
              // if(this.eventPeopleIds.length>0){
              //   let eventPeoples = []
              //   let eventPeopleIds = []
              //   this.eventPeopleIds.forEach(item=>{
              //     eventPeoples.push(item.userName)
              //     eventPeopleIds.push(item.userId)
              //   })
              //   this.form.eventPeoples= eventPeoples.toString()
              //   this.form.eventPeopleIds= eventPeopleIds.toString()
              // }


              // saveevent(this.form).then(res=>{
              //   if(res.code===200){
              //     this.showAddModel = false
              //     this.$message.success(res.msg)
              //     this.loadeventslist(this.paramsData)
              //   }
              // })
            }else {
              this.$message.error('请选择地址')
            }
          }else {
            this.$message.error('请选择事件类型')
          }
        }
      })
    },
     getNetwork(){
      let that=this
      let file=this.getfile(this.form.locations)     
      let parames={
          service:'WFS',
          request:'GetFeature',
          typeName:'gas:patrol_networks',
          outputFormat:'application/json',
          version:'1.0.0',
          filter:file
      }
      
      geoOws(parames).then(res => {
        let features=res.features
        if(features&&features.length>0){
          let ids=[]
          features.forEach(item => {
             ids.push(item.properties.id)
          });
         this.form.networkIds=ids.toString()
        }  
     
        that.saveForm()
           
         
      })
    },
    saveForm(){
        saveevent(this.form).then(res=>{
                if(res.code===200){
                  this.showAddModel = false
                  this.$message.success(res.msg)
                  this.loadeventslist(this.paramsData)
                }
              })
    },
    getfile(lnglat){
      let file='<Filter xmlns:gml="http://www.opengis.net/gml">'+
                       ' <And>   <PropertyIsGreaterThan>'+
                        '  <PropertyName>general_status</PropertyName>'+
                         ' <Literal>0</Literal>'+
                        ' </PropertyIsGreaterThan> <Intersects>'+
                        ' <PropertyName>location</PropertyName>'+
                      '   <gml:Point>'+
                            '  <gml:coordinates>'+lnglat[0]+','+lnglat[1]+'</gml:coordinates>'+
                        ' </gml:Point>'+
                    '  </Intersects> </And>'+
                '  </Filter>'            
      return file
    },
    closeModel(address,lnglat){
      if (!address && !lnglat) {
        this.showModel = false
      } else {
        this.form.locations = lnglat
        this.form.eventAddress = address
        this.showModel = false
        this.$forceUpdate()
      }
    },
    del(row){
      let arr =  row.checkingPoint==0?'设置巡检点':'取消巡检点'
      let type = row.checkingPoint==0?'1':'0'
      this.$confirm(`是否${arr}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        setcheckpoint({id:row.id,setType:type}).then(res=>{
          this.$message({
            message: '设置成功',
            type: 'success'
          });
          this.paramsData.current = 1
          this.loadeventslist(this.paramsData)
        })
      })
    },
    handle(row) {
      this.$confirm(`是否关闭事件?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        closeevent({id:row.id}).then(res=>{
          this.$message({
            message: '关闭成功',
            type: 'success'
          });
          this.paramsData.current = 1
          this.loadeventslist(this.paramsData)
        })
      })
    },
    seeDetil(row){
      this.detiltable = row
      this.detilVisible = !this.detilVisible
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    MutiDeleteFun(){
      if(this.multipleSelection.length>0){
        this.$confirm(`是否批量删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          let arr=[]
          this.multipleSelection.forEach(item=>{
            arr.push(item.id)
          })
          deleteBatchevent({ids:arr}).then((res)=>{
            if(res.code===200){
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.paramsData.current = 1
              this.loadeventslist(this.paramsData)
            }else {
              this.$message({
                message: '删除失败',
                type: 'error'
              });
            }
          })
        })
      }else {
        this.$message.error('未选择删除项目')
      }
    },
    delhandle(row){
      this.$confirm(`是否删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        let arr=[]
        arr.push(row.id)
        deleteBatchevent({ids:arr}).then((res)=>{
          if(res.code===200){
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.paramsData.current = 1
            this.loadeventslist(this.paramsData)
          }else {
            this.$message({
              message: '删除失败',
              type: 'error'
            });
          }
        })
      })
    },
    search(){
      if(this.DataPicker&&this.DataPicker.length>0){
        this.paramsData.startTime =  this.DataPicker[0]
        this.paramsData.endTime =  this.DataPicker[1]
      }else{
        this.paramsData.startTime =  null
        this.paramsData.endTime =  null
      }
      this.paramsData.current = 1
      this.loadeventslist(this.paramsData)
    },
    resize(){
      this.paramsData={
        current:1,
        size: 100,
        searchText:'',
        eventStatus:'',
        checkpoint:''
      }
      this.DataPicker = []
      this.loadeventslist(this.paramsData)
    },
    handleCurrentChange(val){
      this.paramsData.current=  val
      this.loadeventslist(this.paramsData)
    },
    handleSizeChange(val){
      this.paramsData.size=  val
      this.loadeventslist(this.paramsData)
    },
    loadeventslist(obj){
      eventPage(obj).then(res=>{
        this.tableData = res.data.records
        this.eventstotal = res.data.total
      })
    },
  },
  mounted() {
      zypatrolGetUserList({code:'managerPerson'}).then(res=>{
        this.peopleList = res.data
      })
    this.loadeventslist(this.paramsData)
  }
}
</script>

<style scoped lang='less'>
.activeClass{
  background-color: #F56C6C;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px;
  margin-left:20px
}
/*.noactiveClass:hover{*/
/*  background-color: #F56C6C;*/
/*  color: #FFFFFF;*/
/*  cursor: pointer;*/
/*}*/
.noactiveClass{
  padding: 10px;
  margin-left:20px
}
.contain{
  background-color:transparent;
}
// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 20px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // 面包屑
  .crumbs{
    padding: 10px 15px 20px;
    span{
      color: #3069E1;
    }
  }
}
// 搜索
.searchForm{
  display: flex;
   flex-wrap: wrap;
    // justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 20px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
}
</style>
